import styled from 'styled-components';
import { pxtorem } from '../../utils/Tools';
import { StyledHeroBanner } from '../HeroBanner/HeroBanner.style';

export const StyledGettingStartedPage = styled.div`
  ul {
    list-style: none;
  }

  .gs-content {
    padding-inline: var(--gutter);
  }

  ${StyledHeroBanner} {
    h1 {
      max-width: 15ch;
      margin-inline: auto;
    }

    @media (max-width: 768px) { 
      h1 {
        font-size: ${pxtorem(64)};
      }
    }
  }
`;

export const StyledGSItem = styled.div<{ chWidth: number }>`
  padding: ${pxtorem(160)} ${pxtorem(49)};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row wrap;

  &:not(:last-child) {
    border-bottom: 1px solid #686868;
  }

  h4 {
    text-transform: uppercase;
    max-width: ${(p) => (p.chWidth ? `${p.chWidth}ch` : '15ch')};
    
    font: clamp(${pxtorem(48)}, 5vw, ${pxtorem(64)}) / 112.5% var(--primaryFont);

    @media (min-width: 1680px) {
      font: ${pxtorem(80)} / ${pxtorem(72)} var(--primaryFont);
    }
  }

  p {
    margin: 0;
    max-width: 600px;
    font: ${pxtorem(20)} / ${pxtorem(30)} var(--secondaryFont);
  }


  @media (max-width: 900px) {
   padding: ${pxtorem(160)} 0; 
  }
`;

import React from 'react';
import { GettingStartedData } from '../../pages/getting-started';
import { HeroBanner } from '../HeroBanner/HeroBanner';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import {
  StyledGettingStartedPage,
  StyledGSItem,
} from './GettingStartedPage.style';

export const GettingStartedPage: React.FC<GettingStartedPageProps> = ({
  gettingStarted,
  heroBanner,
}) => {
  return (
    <StyledGettingStartedPage>
      <HeroBanner data={heroBanner[0]} />
      <section className="gs-content">
        {gettingStarted.map(({ details, title }) => {
          let customChWidth = undefined;
          if (title.includes(',')) {
            customChWidth = title.split(',')[0].length;
          }

          if (title.includes('&')) {
            customChWidth = title.split('&')[0].length;
          }

          return (
            <StyledGSItem chWidth={customChWidth} key={title}>
              <ShowOnScroll>
                <h4>{title}</h4>
              </ShowOnScroll>
              <ShowOnScroll delay={100}>
                <p dangerouslySetInnerHTML={{ __html: details }} />
              </ShowOnScroll>
            </StyledGSItem>
          );
        })}
      </section>
    </StyledGettingStartedPage>
  );
};

interface GettingStartedPageProps {
  heroBanner: GettingStartedData['heroBanner'];
  gettingStarted: GettingStartedData['gettingStarted'];
}

import { graphql } from "gatsby";
import React   from "react";
import { GettingStartedPage } from "../components/GettingStartedPage/GettingStartedPage";
import { HeroBannerData } from "../components/HeroBanner/HeroBanner";
import { Page } from "../components/Page";
import { SEOProps, SEO } from "../components/SEO/SEO";
import { StoryItemData } from "../components/StoryItem/StoryItem";
import { paths } from "../utils/paths";
import { withLang } from "../utils/reactTools";
import { getLocaleMdData } from "../utils/Tools";

export const GettingStarted: React.FC<GettingStartedProps> = ({ data, language }) => {
  const _data = getLocaleMdData(data);

  let showMissingFrench: boolean = false;
  if (
    language === "fr" &&
    _data["fr"].heroBanner[0].text === _data["en"].heroBanner[0].text
  ) {
    showMissingFrench = true;
  }
  const { pageTheme, seo, gettingStarted, heroBanner } = _data[language] || {};

  const globalSEO: SEOProps =
    (data as any).globalSEO?.fields.locales[language].seo[0] || {};

  const { title, description, ogImage } = (seo ? seo[0] : null) || {};

  return (
    <Page
      background={pageTheme}
      showMissingFrenchBanner={showMissingFrench}
      language={language}
      slugs={{
        en: `${paths.en.prefix}/getting-started`,
        fr: `/${paths.fr.prefix}/commencer`,
      }}
    >
      <SEO
        title={title ? title : globalSEO.title}
        description={description ? description : globalSEO.description}
        ogImage={ogImage ? ogImage : globalSEO.ogImage}
      />
      <GettingStartedPage
        gettingStarted={gettingStarted} 
        heroBanner={heroBanner}
        />
    </Page>
  );
  //#endregion
};

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: { regex: "/misc/seo.md/" }) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
          }
        }
      }
    }

    markdownRemark(fileAbsolutePath: { regex: "/pages/getting-started.md/" }) {
      fields {
        locales {
          en {
            pageTheme
            ...SeoFragmentEN
            ...heroBannerFragmentEN
            gettingStarted {
              title
              details
            }
          }
          fr {
            heroBanner {
              text
            }
            gettingStarted {
              title
              details
            }
          }
        }
      }
    }

  }
`;

export interface GettingStartedData {
  pageTheme: ThemeBG;
  seo: SEOProps[];
  heroBanner: HeroBannerData[];
  gettingStarted: {
    title: string;
    details: string;
  }[]
}

export type GettingStartedProps = LocalizedAllMarkdownDataRelation<
  GettingStartedData,
  StoryItemData
> &
  PropsWithLang<StoryItemData>;


export default withLang(GettingStarted, "en");

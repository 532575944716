import React, { useEffect } from 'react';
import { Wrapper } from '../../styles/Wrapper.style';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledHeroBanner } from './HeroBanner.style';

export const HeroBanner: React.FC<HeroBannerProps> = ({ data, tag = 'h1', children }) => {
  //#region Hooks / Lifecycles
  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables
  const { text, subtitle, textSize = 'normal' } = data;
  const Tag = `${tag}` as keyof JSX.IntrinsicElements;
  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }
  //#endregion

  //#region Templating
  return (
    <StyledHeroBanner>
      <Wrapper width={textSize == 'normal' ? 1817 : 1513}>
        <ShowOnScroll>
          <Tag className={`text text--${textSize}`}>
            {text}
          </Tag>
        </ShowOnScroll>
        {subtitle && (
          <ShowOnScroll delay={100}>
            <span className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></span>
          </ShowOnScroll>
        )}
        {children}
      </Wrapper>
    </StyledHeroBanner>
  )
  //#endregion
}

interface HeroBannerProps {
  data: HeroBannerData;
  tag?: string;
}

export interface HeroBannerData {
  text: string;
  textSize?: 'normal' | 'smaller';
  subtitle?: string;
}
import styled from 'styled-components';
import { pxtorem } from '../../utils/Tools';

export const StyledHeroBanner = styled.section`
  padding: ${pxtorem(60)} 0 ${pxtorem(120)};
  text-align: center;

  .text {
    font: 300 max(8.333vw, 56px)/90% var(--primaryFont);
    text-transform: uppercase;
    margin: 0;
    @media (min-width: 1920px) {
      font: 300 160px/90% var(--primaryFont);
    }

    &--smaller {
      font: 300 max(6.25vw, 44px)/90% var(--primaryFont);
      @media (min-width: 1920px) {
        font: 300 120px/90% var(--primaryFont);
      }
    }
  }

  .subtitle {
    display: block;
    margin-top: max(5.2083vw, 48px);
    font: 400 ${pxtorem(16)}/${pxtorem(20)} var(--secondaryFont);
    text-transform: uppercase;
    @media (min-width: 1920px) {
      margin-top: 100px;
    }
  }

  @media (max-width: 768px) {
    padding: 40px 0;
  }
`